export const jobs = [
  {
    Position: "Software Development Engineer",
    Company: "Amazon Web Services",
    Location: "Toronto, ON",
    Timeline: "October 2024 — Current",
    Skills: ["Python", "Ruby", "Git"],
  },
  {
    Position: "Full Stack Developer",
    Company: "Bell Canada",
    Location: "Toronto, ON",
    Timeline: "October 2021 — October 2024",
    Skills: [
      "Ruby",
      "Rails",
      "Python",
      "JavaScript",
      "jQuery",
      "React",
      "Docker",
      "Git",
    ],
  },
  {
    Position: "Full Stack Developer",
    Company: "IBI Group",
    Location: "Toronto, ON",
    Timeline: "April 2021 — October 2021",
    Skills: ["C#", ".NET", "Bot Framework", "Git"],
  },
  {
    Position: "Administrative & IT Assistant",
    Company: "AWIN",
    Location: "Thornhill, ON",
    Timeline: "June 2020 — March 2021",
    Skills: ["Python"],
  },
  {
    Position: "Teacher",
    Company: "Triway Education",
    Location: "Markham, ON",
    Timeline: "June 2017 — August 2017",
    Skills: ["Python"],
  },
];
